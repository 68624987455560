@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/common/src/ui/index.css';

@import 'tailwindcss/utilities';

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}
